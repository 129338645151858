import { memo } from "react";
import GraphPart from "./GraphPart";

type Props = {};

function Top({}: Props) {
	return (
		<div className="bg-white p-2 rounded-md h-[300px] py-2 border">
			<div className="border-b">
				<div className="pb-1 px-2">
					<p className="font-semibold">Real Time Status</p>
				</div>
			</div>
			<GraphPart />
		</div>
	);
}

export default memo(Top);
