/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-unused-expressions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import AttendancePart from "./AttendancePart";
import BottomPart from "./BottomPart";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import axios from "../../../utils/axios";
import { useState } from "react";
import { CircularProgress } from "@mui/material";
import { useDispatch } from "react-redux";
import { APP_DISPATCH, ROOT_STATE, showNotification } from "../../../redux";
import useFetch from "../../useFetch";
import { currency_map } from "../../../constants/currency_map";
import { useSelector } from "react-redux";
import { PRODUCT_ID, UNOLO_BIOMETRIC } from "../../../constants";
type Props = {};

function MainOverview({}: Props) {
	const dispatch = useDispatch<APP_DISPATCH>();

	const [loading, setLoading] = useState(false);
	const { data: currencyData, loading: isCurrencyLoading } = useFetch<any>("/preferences/account");
	const isloading = loading || isCurrencyLoading;
	const currencySymbol = currency_map[currencyData.data?.currencyCode]?.symbol || "";
	const {
		susbscriptionMetadata: { data: subsMetadata },
	} = useSelector((state: ROOT_STATE) => ({
		susbscriptionMetadata: state.susbscriptionMetadata,
	}));
	const productID = window ? localStorage.getItem(PRODUCT_ID) ?? "2" : "2";

	const handleDownload = async () => {
		setLoading(true);
		let data;
		try {
			const URL: string = `${process.env.NEXT_PUBLIC_BACKEND_URL}/api/v1/${
				productID !== UNOLO_BIOMETRIC ? "downloadDashboardExcel" : "downloadBiometricDashboardExcel"
			}`;
			data = await axios.get(URL, {
				headers: {
					"Content-Type": "application/json;charset=UTF-8",
				},
				responseType: "arraybuffer",
			});
		} catch (err: any) {
			const errorMessage = JSON.parse(new TextDecoder().decode(err.response.data));
			dispatch(showNotification({ message: errorMessage?.error, severity: "error" }));
			setLoading(false);
			return;
		}
		const url = window.URL.createObjectURL(new Blob([data.request.response]));
		const link = document.createElement("a");
		link.href = url;
		link.setAttribute("download", "dashboard.xlsx");
		document.body.appendChild(link);
		link.click();
		link.remove();
		setLoading(false);
	};
	return (
		<div className="py-4">
			<div className="flex items-center justify-between pb-4">
				<p className="text-xl font-semibold">Realtime Dashboard</p>
				{isloading ? (
					<CircularProgress />
				) : (
					<div
						className="bg-primary-600 text-white pr-2 pl-1  py-1 transition ease-in-out hover:shadow rounded-md font-medium text-sm flex items-center gap-1 cursor-pointer"
						onClick={handleDownload}>
						<ArrowDownwardIcon sx={{height: "18px"}}/>
						<button>Attendance Status</button>
					</div>
				)}
			</div>
			<AttendancePart />
			<BottomPart currencySymbol={currencySymbol} subsMetadata={subsMetadata} />
		</div>
	);
}

export default MainOverview;
