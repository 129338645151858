/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-key */

import { CircularProgress, OutlinedInput, Select } from "@mui/material";
import { useEffect, useState } from "react";
import { DG_STYLES } from "../../../../../constants";
import { FormControl, MenuItem } from "@mui/material";
import { Theme, useTheme } from "@mui/material/styles";
import { DataGridPro, GridOverlay, GridValueGetterParams } from "@mui/x-data-grid-pro";
import ImageAndNameCell from "../../../../common/datagrid/ImageAndNameCell";
import _cloneDeep from "lodash/cloneDeep";

type ROW_TYPE = {
	name: string;
	id: string;
	img: string;
	location?: string;
	status?: string;
};

type Props = {
	teamsList: any;
	names: any;
	handleOffDutyEventChange: any;
	offDutyEvents: any;
	selectedTeams: any;
	handleTeamChange: any;
	rows: ROW_TYPE[];
	loading: boolean;
};
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: 150,
		},
	},
};
function getStyles(name: string, listItem: string[], theme: Theme) {
	return {
		fontWeight: listItem?.indexOf(name) === -1 ? theme.typography.fontWeightRegular : theme.typography.fontWeightMedium,
	};
}

const Bottom = ({
	rows,
	teamsList,
	names,
	offDutyEvents,
	handleOffDutyEventChange,
	selectedTeams,
	handleTeamChange,
	loading,
}: Props) => {
	const theme = useTheme();
	const [filteredRows, setFilteredRows] = useState<any[]>([]);
	const [searchTerm, setSearchTerm] = useState<string>("");

	const COLUMNS = [
		{
			field: "employeeName",
			headerName: "Employee",
			sortable: false,
			minWidth: 150,
			renderCell: (params: GridValueGetterParams) => (
				<div className="name_wrapperz">
					<div style={{ whiteSpace: "break-spaces" }}>
						<ImageAndNameCell name={params?.row?.firstName + " " + params?.row?.lastName} avatar={params.row.avatar} />
					</div>
				</div>
			),
			flex: 1,
		},
		{
			field: "teamName",
			headerName: "Team Name",
			sortable: false,
			flex: 1,
			minWidth: 150,
			renderCell: (params: GridValueGetterParams) => <div>{params?.row?.profileName}</div>,
		},
		{
			field: "status",
			headerName: "Status",
			sortable: false,
			minWidth: 150,
			flex: 1,
			renderCell: (params: GridValueGetterParams) => <div>{params?.row?.workingStatusV2}</div>,
		},
	];

	useEffect(() => {
		if (rows?.length > 0 && searchTerm !== "") {
			const tempArray = _cloneDeep(rows);
			setFilteredRows(
				tempArray.filter((row: any) => {
					const sent = (row.firstName + row.lastName + row.profileName + row.workingStatusV2)
						.replace(/ /g, "")
						.toLowerCase();
					return sent.includes(searchTerm.replace(/ /g, "").toLowerCase());
				})
			);
		} else {
			setFilteredRows(rows);
		}
	}, [rows, searchTerm]);

	return (
		<div className="col-span-2 row-span-1 bg-white rounded-md h-[300px] flex flex-col border">
			<div className="flex items-center justify-between flex-wrap py-2 px-3 gap-2">
				<div className="">
					<div>Off Duty Employees ({filteredRows?.length})</div>
				</div>

				<div className="flex-auto flex items-center justify-end gap-2 flex-wrap">
					<FormControl>
						<Select
							labelId="demo-multiple-name-label"
							id="demo-multiple-name"
							multiple
							value={selectedTeams}
							onChange={handleTeamChange}
							input={<OutlinedInput label="Name" />}
							MenuProps={MenuProps}
							sx={{
								height: "2.5rem",
								borderRadius: "3rem",
								fontSize: "14px",
								fontFamily: "SFUIText-Semibold",
								paddingTop: "0",
								backgroundColor: "white",
								border: "1px solid #DFDFDF",
								width: "80px",
							}}>
							<MenuItem value={"all"} key={"all"} style={getStyles("all", selectedTeams, theme)}>
								All
							</MenuItem>
							{teamsList?.data?.map((team: any) => (
								<MenuItem key={team?.profileID} value={team?.profileName} style={getStyles(team, selectedTeams, theme)}>
									{team?.profileName}
								</MenuItem>
							))}
						</Select>
					</FormControl>
					<FormControl>
						<Select
							labelId="off-duty-employee-search-label"
							id="off-duty-employee-search"
							multiple
							value={offDutyEvents}
							onChange={handleOffDutyEventChange}
							input={<OutlinedInput label="Name" />}
							MenuProps={MenuProps}
							sx={{
								height: "2.5rem",
								borderRadius: "3rem",
								fontSize: "14px",
								fontFamily: "SFUIText-Semibold",
								paddingTop: "0",
								backgroundColor: "white",
								border: "1px solid #DFDFDF",
								width: "80px",
							}}>
							<MenuItem value={"all"} key={"all"} style={getStyles("all", offDutyEvents, theme)}>
								All
							</MenuItem>
							{names?.map((name: any) => (
								<MenuItem key={name} value={name} style={getStyles(name, offDutyEvents, theme)}>
									{name}
								</MenuItem>
							))}
						</Select>
					</FormControl>

					<div style={{ maxWidth: "180px", minWidth: "100px", display: "flex" }}>
						<input
							className="search"
							value={searchTerm}
							style={{ maxWidth: "150px", minWidth: "100px", display: "flex" }}
							placeholder="Search Here"
							onChange={(e) => setSearchTerm(e.target.value)}
						/>
					</div>
				</div>
			</div>
			<div className="px-3 pb-3 flex-auto mt-[10px] relative w-full datagrid-default-styles">
				{!loading ? (
					<DataGridPro
						sx={DG_STYLES}
						rows={filteredRows || []}
						columns={COLUMNS}
						rowHeight={55}
						getRowId={(row) => row?.employeeID}
						disableSelectionOnClick
						disableColumnFilter
						hideFooterPagination
						hideFooter
						components={{
							NoRowsOverlay: function CustomNoRowsOverlay() {
								return <GridOverlay>No Data found</GridOverlay>;
							},
						}}
					/>
				) : (
					<div className="flex items-center justify-center h-full">
						<CircularProgress />
					</div>
				)}
			</div>
		</div>
	);
};

export default Bottom;
