/* eslint-disable react-hooks/exhaustive-deps */
import { gql, useQuery } from "@apollo/client";
import { CircularProgress, FormControl, MenuItem, Select } from "@mui/material";
import moment from "moment";
import { useEffect, useState } from "react";
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer, Text } from "recharts";
type Props = {};
export const GET_ATTENDANCE_DASHBOARD_DATA = gql`
	query getattendanceDashboardData(
		$startDate: Date!
		$endDate: Date!
		$limitToCount: Int!
		$groupByKeys: [GroupByKey]!
		$sortByKeys: [SortByKey]
		$orderBy: [OrderBy]
	) {
		get_attendance_dashboard_data(
			startDate: $startDate
			endDate: $endDate
			limitToCount: $limitToCount
			groupByKeys: $groupByKeys
			sortByKeys: $sortByKeys
			orderBy: $orderBy
		) {
			totalEmployeeCount
			employeesWithCurrentAttendanceType
			groupByKeyComb
			workingHours
		}
	}
`;
type GRAPH_DATA_FORMAT = {
	name: string;
	CurrentWeek?: number;
};
function Left({}: Props) {
	const [duration, setDuration] = useState("month");
	const [loading, setLoading] = useState(true);

	const { data: graphData, loading: loading1 } = useQuery(GET_ATTENDANCE_DASHBOARD_DATA, {
		variables: {
			startDate:
				duration == "week"
					? moment().startOf("isoWeek").format("YYYY-MM-DD")
					: moment().startOf("month").format("YYYY-MM-DD"),
			endDate:
				duration == "week"
					? moment().endOf("isoWeek").format("YYYY-MM-DD")
					: moment().endOf("month").format("YYYY-MM-DD"),
			limitToCount: 10000,
			groupByKeys: ["DATE"],
			sortByKeys: ["value"],
			orderBy: ["asc"],
		},
	});
	const [currentWeekData2, setCurrentWeekData2] = useState<{
		[key: string]: number;
	}>({});
	const [compositeGraphData, setCompositeGraphData] = useState<GRAPH_DATA_FORMAT[]>([]);
	const currentWeekData1 = {};
	const formatDataForCurrentWeek = (currentWeekData: any[]) => {
		const startOfWeek =
			duration == "week"
				? moment(new Date()).startOf("isoWeek").format("YYYY-MM-DD")
				: moment(new Date()).startOf("month").format("YYYY-MM-DD");
		const endOfWeek =
			duration == "week"
				? moment(new Date()).endOf("isoWeek").add(1, "days").format("YYYY-MM-DD")
				: moment(new Date()).endOf("month").add(1, "days").format("YYYY-MM-DD");
		let date = startOfWeek;
		while (date != endOfWeek) {
			let isFound = false;
			if (currentWeekData?.length === 0) {
				currentWeekData1[date] = 0;
			} else {
				currentWeekData?.every(
					(item: {
						value: any;
						groupByKeyComb: string[];
						quantity: number;
						workingHours: number;
						totalEmployeeCount: number;
					}) => {
						if (item.groupByKeyComb[0] == date) {
							isFound = true;
						}
						if (isFound) {
							currentWeekData1[date] = (item?.workingHours / (1000 * 60 * 60)).toFixed(2);
							return false;
						} else {
							currentWeekData1[date] = 0;
							return true;
						}
					}
				);
			}
			date = moment(date).add(1, "days").format("YYYY-MM-DD");
		}
		//currentWeekData1[endOfWeek] = 0;

		setCurrentWeekData2(currentWeekData1);
	};
	const formatFinalDataForTheGraph = () => {
		const arr1: GRAPH_DATA_FORMAT[] = [];
		for (const [key, value] of Object.entries(currentWeekData2)) {
			arr1.push({
				name: moment(key).format("DD-MM"),
				CurrentWeek: value,
			});
		}
		// eslint-disable-next-line no-unused-vars
		setCompositeGraphData([...arr1]);
		setLoading(false);
	};

	const CustomizedLabelB = () => (
		<Text
			x={150}
			y={0}
			dx={-300}
			dy={20}
			textAnchor="start"
			width={180}
			transform="rotate(-90)"
			style={{
				fontFamily: "SFUIText-Regular, Helvetica, Arial, sans-serif",
				color: "rgb(102, 102, 102)",
				marginRight: "100px",
				fill: "rgb(102, 102, 102)",
				fontSize: "14px",
			}}>
			{`Working Hours`}
		</Text>
	);

	useEffect(() => {
		formatFinalDataForTheGraph();
	}, [currentWeekData2]);
	useEffect(() => {
		if (!loading1) {
			formatDataForCurrentWeek(graphData?.get_attendance_dashboard_data);
		} else {
			setLoading(true);
		}
	}, [loading1]);
	return (
		<div className="bg-white rounded-md border px-3 py-2 flex flex-col min-h-[300px]">
			<div className="flex items-center justify-between pb-2 gap-2">
				<div>Daily Average Working Hours</div>
				<div className="flex items-center justify-end gap-2">
					<FormControl fullWidth>
						<Select
							labelId="demo-simple-select-label"
							id="demo-simple-select"
							value={duration}
							label={""}
							onChange={() => {
								if (duration === "week") {
									setLoading(true);
									setDuration("month");
								} else {
									setLoading(true);
									setDuration("week");
								}
							}}
							sx={{
								width: "10rem",
								height: "2.5rem",
								borderRadius: "3rem",
								fontSize: "14px",
								fontFamily: "SFUIText-Semibold",
								paddingTop: "0",
								backgroundColor: "white",
								border: "1px solid #DFDFDF",
								"& .MuiOutlinedInput-input": {},
							}}>
							<MenuItem value={"week"}>This Week</MenuItem>
							<MenuItem value={"month"}>This Month</MenuItem>
						</Select>
					</FormControl>
				</div>
			</div>
			{loading ? (
				<div className="flex-auto flex items-center justify-center">
					<CircularProgress />
				</div>
			) : (
				<div className="flex-auto w-full h-full">
					<ResponsiveContainer width={"100%"} height={"100%"}>
						<BarChart data={compositeGraphData}>
							<XAxis dataKey="name" fontSize={14} />
							<YAxis label={<CustomizedLabelB />} tickFormatter={(tick: any) => `${tick}`} fontSize={14} />
							<Tooltip wrapperStyle={{ backgroundColor: "#ccc" }} />
							<Bar dataKey="CurrentWeek" fill="#0F45FF" barSize={20} name="Working Hours" />
						</BarChart>
					</ResponsiveContainer>
				</div>
			)}
		</div>
	);
}

export default Left;
