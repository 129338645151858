// import CountUp from "react-countup";
// import VisibilitySensor from "react-visibility-sensor";

import { CircularProgress } from "@mui/material";
import { PRODUCT_ID, UNOLO_BIOMETRIC } from "../../../../../../../constants";
import { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import { GET_FE_STATUS } from "../../../../../../../schema";

function ListPart() {
	const [teamDataToShow, setTeamDataToShow] = useState<any>(null);
	const { data: teamData, loading: loadingTeamData } = useQuery(GET_FE_STATUS, {
		variables: {
			teamFilter: true,
		},
	});
	useEffect(() => {
		if (!loadingTeamData && teamData?.get_attendance_summary && Array.isArray(teamData?.get_attendance_summary)) {
			setTeamDataToShow(teamData?.get_attendance_summary);
		}
	}, [loadingTeamData, teamData?.get_attendance_summary]);
	const productID = window ? localStorage.getItem(PRODUCT_ID) ?? "2" : "2";

	return (
		<div className="p-2 my-2 flex-auto overflow-auto">
			{loadingTeamData ? (
				<div className="flex items-center justify-center h-full">
					<CircularProgress />
				</div>
			) : (
				<div className="flex flex-col gap-2">
					{teamDataToShow
						?.sort((a: any, b: any) => a.profile.profileName.localeCompare(b.profile.profileName))
						.map((item: any, index: any) => (
							<div className="grid grid-cols-2 items-center justify-between gap-2" key={index}>
								<div className="font-medium text-sm flex-auto col-span-1">
									{item.profile.profileName.length > 15
										? `${item.profile.profileName.substring(0, 15)}...`
										: item.profile.profileName}
								</div>
								<div className="grid grid-cols-3 gap-2 col-span-1">
									<div className="flex gap-2 items-center text-sm">
										<div className="h-2 w-2 rounded-full" style={{ backgroundColor: "#40A636" }}></div>
										<div>{item.attendanceSummary?.onlineUsers}</div>
									</div>
									{productID !== UNOLO_BIOMETRIC ? (
										<div className="flex gap-2 items-center text-sm">
											<div className="h-2 w-2 rounded-full" style={{ backgroundColor: "#EF9943" }}></div>
											<div>{item.attendanceSummary?.inactiveUsers}</div>
										</div>
									) : null}
									<div className="flex gap-2 items-center text-sm">
										<div className="h-2 w-2 rounded-full" style={{ backgroundColor: "#F54747" }}></div>
										<div>{item.attendanceSummary?.offlineUsers}</div>
									</div>
								</div>
							</div>
						))}
				</div>
			)}
		</div>
	);
}

export default ListPart;
