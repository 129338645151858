import Right from "./Right";
import Left from "./Left";
import BiometricRight from "./BiometricRight";
import { PRODUCT_ID, UNOLO_BIOMETRIC } from "../../../../../constants";

type Props = {
	subsMetadata: any;
};

function Bottom({subsMetadata}: Props) {
	const productID = window ? localStorage.getItem(PRODUCT_ID) ?? "2" : "2";
	// Right Part is for tasks which we don't want in UNOLO_BIOMETRIC
	return (
		<>
			<Left />
			{productID === UNOLO_BIOMETRIC ? <BiometricRight /> :  subsMetadata?.tasks === 1 ? <Right />: null}
		</>
	);
}

export default Bottom;
