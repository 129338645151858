import { memo } from "react";
import Top from "./Top";
import TopRight from "./TopRight";

type Props = {};

function Left({}: Props) {
	return (
		<div className="col-span-2 row-span-1 grid sm:grid-cols-1 md:grid-cols-2 gap-3">
			<Top />
			<TopRight />
		</div>
	);
}
export default memo(Left);
