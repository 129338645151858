import React, { memo } from "react";
import ListPart from "./ListPart";

type Props = {};

function Top({}: Props) {
	return (
		<div className="bg-white p-2 rounded-md h-[300px] flex flex-col border">
			<div className="border-b">
				<div className="pb-1 px-2">
					<p>Teamwise Attendance</p>
				</div>
			</div>
			<ListPart />
		</div>
	);
}

export default memo(Top);
