import { useQuery } from "@apollo/client";
import { LinearProgress, Tooltip } from "@mui/material";
import { DataGridPro, GridColDef, GridValueGetterParams, DataGridProProps } from "@mui/x-data-grid-pro";
import moment from "moment";
import "moment-timezone";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ROOT_STATE } from "../../../../../../redux";
import { GET_FE_STATUS } from "../../../../../../schema";
import ImageAndNameCell from "../../../../../common/datagrid/ImageAndNameCell";
import { DEFAULT_TIMEZONE, DG_STYLES, PRODUCT_ID, UNOLO_BIOMETRIC } from "../../../../../../constants/index";
import CustomColumnMenu from "../../../../../common/datagrid/CustomColumnMenu";
type ROW_TYPE = {
	name: string;
	id: string;
	img: string;
	location?: string;
	status?: string;
};
type Props = {
	rows: ROW_TYPE[];
	setVariables: any;
	loading1: boolean;
};

function DataGridPart({ rows, setVariables, loading1 }: Props) {
	const productID = window ? localStorage.getItem(PRODUCT_ID) ?? "2" : "2";
	const user = useSelector((state: ROOT_STATE) => state.user);
	const LIMIT = typeof window !== "undefined" && window.innerWidth < 600 ? 10 : window.innerWidth < 960 ? 15 : 20;
	const [count, setCount] = useState<any>(null);
	const { data: dataCount, loading: loadingCount } = useQuery(GET_FE_STATUS, {
		variables: {
			teamFilter: false,
		},
	});

	const getColorStatus = (status: string) => {
		if (status == "Punched In") {
			return "#40A636";
		} else if (status == "Inactive") {
			return "#EF9943";
		} else {
			return "#F54747";
		}
	};

	const COLUMNS = [
		{
			field: "employee",
			headerName: "Employee",
			sortable: false,
			minWidth: 210,
			renderCell: (params: GridValueGetterParams) => (
				<div className="name_wrapperz">
					<Tooltip title={params.row?.status}>
						<div
							className="status"
							style={{
								backgroundColor: getColorStatus(params.row?.status),
							}}
						/>
					</Tooltip>
					<div style={{ whiteSpace: "break-spaces" }}>
						<ImageAndNameCell internalEmployeeID={params.row.internalEmpID} name={params.row.name} avatar={params.row.avatar} />
					</div>
				</div>
			),
			flex: 1,
		},
		{
			field: "attendance",
			headerName: "Attendance",
			sortable: false,
			flex: 1,
			minWidth: 210,
			renderCell: (params: GridValueGetterParams) => (
				<div className="attendance" style={{ color: "#000000", fontFamily: "SFUIText-Regular" }}>
					<div style={{ fontFamily: "SFUIText-Medium", lineHeight: "0.5rem" }}>
						{params.row.lastAttendance}
						<span style={{ fontSize: "12px", lineHeight: "1rem" }}>
							{params.row?.lastAttendanceTimestamp == null
								? null
								: " : " +
								moment(params.row?.lastAttendanceTimestamp)
									.tz(user.tz ?? DEFAULT_TIMEZONE ?? DEFAULT_TIMEZONE)?.calendar(null, {
										sameElse: "DD-MM-YYYY",
									})}
						</span>
					</div>

					<div style={{ whiteSpace: "break-spaces" }}>
						{params.row?.lastAttendanceLocation == null ? null : params.row?.lastAttendanceLocation.length > 70 ? (
							<Tooltip title={params.row?.lastAttendanceLocation}>
								<div>From:{params.row?.lastAttendanceLocation}</div>
							</Tooltip>
						) : (
							<div>From : {params.row?.lastAttendanceLocation}</div>
						)}
					</div>
				</div>
			),
		},
		{
			field: "location",
			headerName: "Last Location",
			sortable: false,
			minWidth: 210,
			flex: 1,
			renderCell: (params: GridValueGetterParams) => (
				<div className="time-and-site-columns">
					<div className="time">
						{params.row.location?.length > 50 ? (
							<Tooltip title={params.row.location}>
								<p>{params.row.location.substring(0, 70) + "..."}</p>
							</Tooltip>
						) : (
							params.row.location
						)}
					</div>
					{params.row?.location == "NA" ? null : (
						<div className="location">
							<span className="label" style={{ color: "#000000" }}>
								{moment(params.row?.lastLocationTimestamp)
									.tz(user.tz ?? DEFAULT_TIMEZONE)
									?.fromNow()}
							</span>
						</div>
					)}
				</div>
			),
		},
	];

	const BIOMETRIC_COLUMNS = [
		{
			field: "employee",
			headerName: "Employee",
			sortable: false,
			minWidth: 210,
			renderCell: (params: GridValueGetterParams) => (
				<div className="name_wrapperz">
					<Tooltip title={params.row?.status}>
						<div
							className="status"
							style={{
								backgroundColor: getColorStatus(params.row?.status),
							}}
						/>
					</Tooltip>
					<div style={{ whiteSpace: "break-spaces" }}>
						<ImageAndNameCell internalEmployeeID={params.row.internalEmpID} name={params.row.name} avatar={params.row.avatar} />
					</div>
				</div>
			),
			flex: 1,
		},
		{
			field: "attendance",
			headerName: "Attendance",
			sortable: false,
			flex: 1,
			minWidth: 210,
			renderCell: (params: GridValueGetterParams) => (
				<div className="attendance" style={{ color: "#000000", fontFamily: "SFUIText-Regular" }}>
					<div style={{ fontFamily: "SFUIText-Medium", lineHeight: "0.5rem" }}>
						{params.row.lastAttendance}
						<span style={{ fontSize: "12px", lineHeight: "1rem" }}>
							{params.row?.lastAttendanceTimestamp == null
								? null
								: " : " +
								moment(params.row?.lastAttendanceTimestamp)
									.tz(user.tz ?? DEFAULT_TIMEZONE ?? DEFAULT_TIMEZONE)
									.calendar(null, {
										sameElse: "DD-MM-YYYY",
									})}
						</span>
					</div>

					<div style={{ whiteSpace: "break-spaces" }}>
						{params.row?.lastAttendanceLocation == null ? null : params.row?.lastAttendanceLocation.length > 70 ? (
							<Tooltip title={params.row?.lastAttendanceLocation}>
								<div>From:{params.row?.lastAttendanceLocation}</div>
							</Tooltip>
						) : (
							<div>From : {params.row?.lastAttendanceLocation}</div>
						)}
					</div>
				</div>
			),
		},
		{
			field: "siteName",
			headerName: "Site Associated",
			sortable: false,
			minWidth: 210,
			flex: 1,
		},
	];
	const [columns] = useState<GridColDef[]>(productID === UNOLO_BIOMETRIC ? BIOMETRIC_COLUMNS : COLUMNS);

	useEffect(() => {
		if (!loadingCount && dataCount?.get_attendance_summary) {
			setCount({ ...dataCount?.get_attendance_summary });
		}
	}, [dataCount?.get_attendance_summary, loadingCount]);

	const handleOnRowsScrollEnd: DataGridProProps["onRowsScrollEnd"] = () => {
		if (
			count &&
			count[0]?.attendanceSummary.totalUsers &&
			rows.length % LIMIT == 0 &&
			rows.length < count[0].attendanceSummary.totalUsers
		) {
			setVariables((v: any) => ({
				...v,
				take: v.take < count[0]?.attendanceSummary.totalUsers ? v.take + LIMIT : v.take,
			}));
		}
	};

	return (
		<div className="flex-auto mb-2 min-h-[400px] datagrid-table">
			<DataGridPro
				sx={DG_STYLES}
				rows={rows}
				columns={columns}
				loading={loading1}
				rowHeight={80}
				disableSelectionOnClick
				disableColumnFilter
				hideFooterPagination
				onRowsScrollEnd={handleOnRowsScrollEnd}
				components={{
					LoadingOverlay: LinearProgress,
					ColumnMenu: CustomColumnMenu,
				}}
				hideFooter
			/>
		</div>
	);
}

export default DataGridPart;
