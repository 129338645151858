import Left from "./Left";
import Right from "./Right";

type Props = {
	currencySymbol: string;
	subsMetadata: any;
};

function Top({ currencySymbol, subsMetadata }: Props) {
	return (
		<>
			{subsMetadata?.tasks === 1 && subsMetadata?.forms === 1 && <Left />}
			{subsMetadata?.expenses === 1 && <Right currencySymbol={currencySymbol} />}
		</>
	);
}

export default Top;
