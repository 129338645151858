import { PRODUCT_ID, UNOLO_BIOMETRIC } from "../../../../constants";
import Bottom from "./Bottom";
import Top from "./Top";

type Props = {
	currencySymbol: string;
	subsMetadata:any;
};

function BottomPart({ currencySymbol, subsMetadata }: Props) {
	const productID = window ? localStorage.getItem(PRODUCT_ID) ?? "2" : "2";
	// Top part contains Expense and Forms which we don't want in UNOLO BIOMETRIC
	return (
		<div className="grid sm:grid-cols-2 gap-3 mt-3 grid-cols-1">
			{productID !== UNOLO_BIOMETRIC && <Top currencySymbol={currencySymbol} subsMetadata={subsMetadata}/>}
			<Bottom subsMetadata={subsMetadata}/>
		</div>
	);
}

export default BottomPart;
