import { useQuery } from "@apollo/client";
import { CircularProgress } from "@mui/material";
import moment from "moment";
import { useEffect, useState } from "react";
import { Cell, Legend, Pie, PieChart, ResponsiveContainer, Tooltip } from "recharts";
import { GET_TOPSTRIP_DATA } from "../../../../../../schema/tasks";
import { useAdminCompanySettings } from "../../../../../useAdminEntitlements";

type Props = {};
type TASK_DATA = {
	quantity: number;
	notYetStarted: number;
	delayed: number;
	inProgress: number;
	completed: number;
	cancelled: number;
};
type GRAPH_DATA = {
	name: string;
	value: number;
	color: string;
};
function Right({}: Props) {
	// const [loading, setLoading] = useState(false);
	// const [duration, setDuration] = useState("today");
	const customTaskFlag = useAdminCompanySettings("customEntityMigrationPhase");
	// o - default
	// 1 - custom and default
	// 2 - custom
	const [graphData, setGraphdata] = useState<GRAPH_DATA[]>([]);
	const [noTask, setNoTask] = useState(false);
	const [taskData, setTaskData] = useState<TASK_DATA>({
		quantity: 0,
		completed: 0,
		cancelled: 0,
		delayed: 0,
		inProgress: 0,
		notYetStarted: 0,
	});
	const { data: data1, loading: loading1 } = useQuery(GET_TOPSTRIP_DATA, {
		variables: {
			startDate: moment().format("YYYY-MM-DD"),
			endDate: moment().format("YYYY-MM-DD"),
			taskType: customTaskFlag === 2 ? "CUSTOMTASK" : customTaskFlag === 1 ? "BOTH" : "DEFAULT",
		},
	});
	const renderColorfulLegendText = (value: string) => (
		<span
			style={{
				fontSize: "12px",
				color: "#000000",
				fontFamily: "SFUIText-Regular, Helvetica, Arial, sans-serif",
				fontWeight: "400",
			}}>
			{value}
		</span>
	);
	const renderLegend = (props: any) => {
		const { payload } = props;
		return (
			<ul className="flex gap-2 items-start justify-evenly mt-4">
				{payload.map(
					(
						entry: {
							color: any;
							value: any;
							payload: {
								value: any;
							};
						},
						index: any
					) => (
						<li className="flex flex-col items-center flex-1" key={`item-${index}`}>
							<div className="flex gap-2 items-center">
								<span className="h-2 w-2 rounded-full" style={{ backgroundColor: entry.color }}></span>
								<span className="text-sm font-semibold">{entry.payload.value}</span>
							</div>
							<div className="text-xs text-center font-medium">
								<div>{entry.value}</div>
							</div>
						</li>
					)
				)}
			</ul>
		);
	};
	const formatDataForGraph = () => {
		const tempArray = [
			{
				name: "Not Yet Started",
				value: data1?.get_task_dashboard_topstrip_data?.taskNotYetStarted,
				color: "#EF9943",
			},
			{
				name: "Delayed",
				value: data1?.get_task_dashboard_topstrip_data?.taskDelayed,
				color: "#25A8F4",
			},
			{
				name: "In Progress",
				value: data1?.get_task_dashboard_topstrip_data?.taskInProgress,
				color: "#0F45FF",
			},
			{
				name: "Completed",
				value: data1?.get_task_dashboard_topstrip_data?.taskCompleted,
				color: "#40A636",
			},
		];
		setGraphdata([...tempArray]);
	};
	useEffect(() => {
		if (!loading1) {
			if (
				data1?.get_task_dashboard_topstrip_data?.taskNotYetStarted == null &&
				data1?.get_task_dashboard_topstrip_data?.taskDelayed == null &&
				data1?.get_task_dashboard_topstrip_data?.taskInProgress == null &&
				data1?.get_task_dashboard_topstrip_data?.taskCompleted == null
			) {
				setNoTask(true);
			}
			const tempTaskData: TASK_DATA = {
				quantity: data1?.get_task_dashboard_topstrip_data?.quantity,
				notYetStarted: data1?.get_task_dashboard_topstrip_data?.taskNotYetStarted,
				delayed: data1?.get_task_dashboard_topstrip_data?.taskDelayed,
				inProgress: data1?.get_task_dashboard_topstrip_data?.taskInProgress,
				completed: data1?.get_task_dashboard_topstrip_data?.taskCompleted,
				cancelled: data1?.get_task_dashboard_topstrip_data?.taskCancelled,
			};
			setTaskData({
				...tempTaskData,
			});
			formatDataForGraph();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [loading1]);

	return (
		<div className="bg-white rounded-md border px-3 py-2 flex flex-col min-h-[300px]">
			<div className="flex items-center justify-between border-b pb-1">
				<div>Task Status Overview</div>
				<div>
					{/* <FormControl fullWidth>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={duration}
              label={""}
              onChange={() => {
                if (duration === "week") {
                  setLoading(true);
                  setDuration("month");
                } else {
                  setLoading(true);
                  setDuration("week");
                }
              }}
              sx={{
                width: "10rem",
                height: "2.5rem",
                borderRadius: "3rem",
                fontSize: "14px",
                fontFamily: "SFUIText-Semibold",
                paddingTop: "0",
                backgroundColor: "white",
                border: "1px solid #DFDFDF",
                "& .MuiOutlinedInput-input": {},
              }}
            >
              <MenuItem value={"today"}>Today</MenuItem>
              <MenuItem value={"week"}>This Week</MenuItem>
              <MenuItem value={"month"}>This Month</MenuItem>
            </Select>
          </FormControl> */}
				</div>
			</div>
			{loading1 ? (
				<div className="flex-auto flex items-center justify-center">
					<CircularProgress />
				</div>
			) : (
				<div className="flex-auto justify-center relative">
					{noTask ? (
						<div className="h-full w-full flex items-center justify-center font-medium text-gray-400">No Tasks Data For Today</div>
					) : (
						<div className="h-full w-full flex">
							<ResponsiveContainer width="100%" height="100%">
								<PieChart>
									<Legend
										align="center"
										layout="horizontal"
										verticalAlign="top"
										height={18}
										wrapperStyle={{ fontSize: "12px" }}
										iconType="circle"
										formatter={renderColorfulLegendText}
										content={renderLegend}
									/>

									<Pie
										data={graphData}
										cy={"85%"}
										startAngle={180}
										endAngle={0}
										innerRadius={"80%"}
										outerRadius={"100%"}
										fill="#8884d8"
										paddingAngle={0}
										dataKey="value">
										{graphData?.map((entry, index) => (
											<Cell key={`cell-${index}`} fill={entry?.color} />
										))}
									</Pie>
									<Tooltip filterNull={false} wrapperStyle={{ zIndex: 1000 }} />
								</PieChart>
							</ResponsiveContainer>
							<div className="flex flex-col absolute -translate-x-1/2 left-1/2 bottom-9 text-center">
								<div>
									{taskData?.quantity ?? 0}
								</div>
								<div>Total Tasks</div>
							</div>
						</div>
					)}
				</div>
			)}
		</div>
	);
}

export default Right;
