import { gql, useQuery, useSubscription } from "@apollo/client";
import React, { useEffect, useState } from "react";
import Bottom from "./Bottom";
import { GraphDataContextProvider } from "./context";
import Left from "./Left";
import Right from "./Right";
import { SelectChangeEvent } from "@mui/material/Select";

import { PROFILES_DATA } from "../../../../@types";
import useFetch from "../../../useFetch";
import _cloneDeep from "lodash/cloneDeep";
const WATCH_ATTENDANCE_EVENT = gql`
	subscription WatchAttendanceEvent {
		attendance {
			employeeID
			eventTypeID
			lat
			lon
			timestamp
			address
		}
	}
`;
export const GET_OFFDUTY_DATA = gql`
	query {
		get_off_duty_users_by_admin_id_v2 {
			data {
				employeeID
				firstName
				lastName
				profileName
				workingStatusV2
			}
		}
	}
`;

function AttendancePart() {
	const [targetData, setTargetData] = useState<
		| {
				employeeID: number;
				eventTypeID: number;
				lat: number;
				lon: number;
				timestamp: string;
				address: string;
				internalEmpID: string;
		  }
		| undefined
	>();
	useSubscription(WATCH_ATTENDANCE_EVENT, {
		onData: ({ data }) => {
			setTargetData(data.data?.attendance);
		},
	});
	const { data: teamsList } = useFetch<PROFILES_DATA[]>("/users/profiles");
	const names = ["Weekly Off", "On Leave", "Public Holiday"];

	const [offDutyEvents, setOffDutyEvents] = React.useState<string[]>(["all"]);
	const [selectedTeams, setSelectedTeams] = React.useState<string[]>(["all"]);

	const [data, setData] = React.useState<any[]>([]);

	const handleOffDutyEventChange = ({ target: { value } }: SelectChangeEvent<typeof offDutyEvents>) => {
		const valueArray = typeof value === "string" ? value.split(",") : value;
		const allSelected = offDutyEvents.includes("all");
		if (valueArray.includes("all")) {
			if (allSelected && valueArray.length > 1) {
				setOffDutyEvents(valueArray.filter((item) => item !== "all"));
			} else if (!allSelected) {
				setOffDutyEvents(["all"]);
			}
		} else {
			setOffDutyEvents(valueArray.length === 0 ? ["all"] : valueArray);
		}
	};
	const handleTeamChange = ({ target: { value } }: SelectChangeEvent<typeof selectedTeams>) => {
		const valueArray = typeof value === "string" ? value.split(",") : value;
		const allSelected = selectedTeams.includes("all");
		if (valueArray.includes("all")) {
			if (allSelected && valueArray.length > 1) {
				setSelectedTeams(valueArray.filter((item) => item !== "all"));
			} else if (!allSelected) {
				setSelectedTeams(["all"]);
			}
		} else {
			setSelectedTeams(valueArray.length === 0 ? ["all"] : valueArray);
		}
	};
	const { data: offDutyData, loading: offDutyLoading } = useQuery(GET_OFFDUTY_DATA);
	useEffect(() => {
		if (!offDutyLoading) {
			setData(offDutyData?.get_off_duty_users_by_admin_id_v2?.data);
		}
	}, [offDutyData]);

	useEffect(() => {
		if (
			offDutyEvents?.length === 1 &&
			selectedTeams?.length === 1 &&
			offDutyEvents[0] === "all" &&
			selectedTeams[0] === "all"
		) {
			setData(offDutyData?.get_off_duty_users_by_admin_id_v2?.data);
		} else {
			const tempArray = _cloneDeep(offDutyData?.get_off_duty_users_by_admin_id_v2?.data);
			let filteredData = tempArray || [];
			// Filter the data based on the selected teams
			if (!selectedTeams.includes("all")) {
				filteredData = filteredData?.filter((row: any) => selectedTeams.includes(row.profileName));
			}
			// Filter the data based on the selected off duty events
			if (!offDutyEvents.includes("all")) {
				filteredData = filteredData?.filter((row: any) => offDutyEvents.includes(row.workingStatusV2));
			}
			setData(filteredData);
		}
	}, [offDutyEvents, selectedTeams]);

	return (
		<GraphDataContextProvider>
			<div className="grid gap-3 lg:grid-cols-4 lg:grid-rows-2 grid-cols-2 grid-rows-1">
				<Left />
				<Right targetData={targetData} />
				<Bottom
					teamsList={teamsList}
					names={names}
					offDutyEvents={offDutyEvents}
					handleOffDutyEventChange={handleOffDutyEventChange}
					selectedTeams={selectedTeams}
					handleTeamChange={handleTeamChange}
					rows={data}
					loading={offDutyLoading}
				/>
			</div>
		</GraphDataContextProvider>
	);
}

export default AttendancePart;
