import { createContext, Dispatch, ReactNode, SetStateAction, useState } from "react";

type Props = {
	children: ReactNode;
};
type GRAPH_DATA = {
	name: string;
	value: number;
	color: string;
};
const GraphDataContext = createContext<{
	graphData: GRAPH_DATA[];
	setGraphdata?: Dispatch<SetStateAction<GRAPH_DATA[]>>;
}>({ graphData: [] });

function GraphDataContextProvider({ children }: Props) {
	const [graphData, setGraphdata] = useState<GRAPH_DATA[]>([]);
	const data = { graphData, setGraphdata };
	return <GraphDataContext.Provider value={data}>{children}</GraphDataContext.Provider>;
}

export { GraphDataContextProvider, GraphDataContext };
