/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { CircularProgress, FormControl, MenuItem, Select } from "@mui/material";
import { Cell, Pie, PieChart, ResponsiveContainer, Tooltip } from "recharts";
import { useEffect, useState } from "react";
import moment from "moment";
import { useQuery } from "@apollo/client";
type Props = {
	currencySymbol: string;
};
type GRAPH_DATA = {
	name: string;
	value: number;
	fill: string;
};
import { GET_EXPENSE_DASHBOARD_DATA } from "../../../../../../schema/expenses";

function Right({ currencySymbol }: Props) {
	const [duration, setDuration] = useState("month");
	const [loading, setLoading] = useState(true);
	const { data: data1, loading: loading1 } = useQuery(GET_EXPENSE_DASHBOARD_DATA, {
		variables: {
			startDate:
				duration == "week"
					? moment().startOf("isoWeek").format("YYYY-MM-DD")
					: moment().startOf("month").format("YYYY-MM-DD"),
			endDate:
				duration == "week"
					? moment().endOf("isoWeek").format("YYYY-MM-DD")
					: moment().endOf("month").format("YYYY-MM-DD"),
			groupByKeys: ["CLAIM_STATUS"],
			sortByKeys: ["value"],
			limitToCount: 10000,
			orderBy: ["asc"],
			expenseOrConveyance: 0,
		},
		fetchPolicy: "no-cache",
	});
	const [showNothingToShow, setSHowNothingToShow] = useState(false);
	const [totalExpenseData, setTotalExpenseData] = useState<{
		quantity: number;
		value: number;
	}>();
	const colorArray = { PENDING: "#EF9943", APPROVED: "#40A636", REJECT: "#F54747", PAIDOUT: "#25A8F4" };
	const [totalApprovedExpenses, setTotalApprovedExpenses] = useState<{
		quantity: number;
		value: number;
	}>();
	const [totalRejectedExpenses, setTotalRejectedExpenses] = useState<{
		quantity: number;
		value: number;
	}>();
	const [totalPendingExpenses, setTotalPendingExpenses] = useState<{
		quantity: number;
		value: number;
	}>();
	const [paidOut, setPaidOut] = useState<{
		quantity: number;
		value: number;
	}>();
	const [pieChartData, setPieChartData] = useState<GRAPH_DATA[]>([]);
	const RADIAN = Math.PI / 180;
	const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent }: any) => {
		const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
		const x = cx + radius * Math.cos(-midAngle * RADIAN);
		const y = cy + radius * Math.sin(-midAngle * RADIAN);

		return (
			<text x={x} y={y} fill="white" textAnchor={x > cx ? "start" : "end"} dominantBaseline="central">
				{`${(percent * 100).toFixed(0)}%`}
			</text>
		);
	};
	// const renderColorfulLegendText = (value: string) => (
	// 	<span
	// 		style={{
	// 			display:"block",
	// 			float:"left",
	// 			color: "#000000",
	// 			fontFamily: "SFUIText-Regular, Helvetica, Arial, sans-serif",
	// 			fontWeight: "300",
	// 			width:"100%",
	// 			fontSize:"8px",
	// 			textAlign:"left"
	// 		}}
	// 	>
	// 		{value}
	// 	</span>
	// );
	const getProperName = (backendName: string): string => {
		if (backendName == "PENDING") {
			return "Request Pending";
		} else if (backendName == "APPROVED") {
			return "Request Approved";
		} else if (backendName == "PAID OUT") {
			return "Paid Out";
		} else if (backendName == "REJECT") {
			return "Request Rejected";
		} else {
			return "";
		}
	};
	const formatDataForPie = (data: any[]) => {
		let total = 0;
		let numbers = 0;
		data.forEach((team) => {
			if (team.groupByKeyComb[0] === "PENDING") {
				setTotalPendingExpenses({
					quantity: team?.quantity,
					value: team?.value,
				});
			} else if (team.groupByKeyComb[0] === "APPROVED") {
				setTotalApprovedExpenses({
					quantity: team?.quantity,
					value: team?.value,
				});
			} else if (team.groupByKeyComb[0] === "REJECT") {
				setTotalRejectedExpenses({
					quantity: team?.quantity,
					value: team?.value,
				});
			} else if (team.groupByKeyComb[0] === "PAID OUT") {
				setPaidOut({
					quantity: team?.quantity,
					value: team?.value,
				});
			}
			total = total + team.value;
			numbers = numbers + team.quantity;
		});
		setTotalExpenseData({
			quantity: numbers,
			value: total,
		});
		const arr1: GRAPH_DATA[] = [];
		data.forEach((team) => {
			arr1.push({
				name: `${getProperName(team.groupByKeyComb[0])} %`,
				value: parseInt(((team.value / total) * 100).toFixed()),
				fill: colorArray[team.groupByKeyComb[0].replace(/ /g, "")],
			});
		});
		setPieChartData([...arr1]);
		setLoading(false);
	};
	// const handleChangeTypeChange = () => {
	//   if (type === "quantity") {
	//     setType("value");
	//   } else {
	//     setType("quantity");
	//   }
	// };
	// const handleChangeDurationChange = () => {
	//   if (duration == "week") {
	//     setDuration("month");
	//   } else {
	//     setDuration("week");
	//   }
	// };
	useEffect(() => {
		if (loading1 === false) {
			if (data1?.get_expenses_dashboard_data?.length > 0) {
				if (showNothingToShow) setSHowNothingToShow(false);
				formatDataForPie(data1?.get_expenses_dashboard_data);
			} else if (data1?.get_expenses_dashboard_data?.length == 0) {
				setSHowNothingToShow(true);
				setLoading(false);
			}
		} else {
			setLoading(true);
		}
	}, [loading1]);

	return (
		<div className="bg-white rounded-md border px-3 py-2 flex flex-col min-h-[250px]">
			<div className="flex items-center justify-between gap-2 flex-wrap border-b pb-2">
				<p> Expense Overview</p>
				<div className="flex items-end">
					<FormControl fullWidth>
						<Select
							labelId="expense-overview-select-label"
							id="expense-overview-select"
							value={duration}
							label={""}
							onChange={() => {
								if (duration === "week") {
									setLoading(true);
									setDuration("month");
								} else {
									setLoading(true);
									setDuration("week");
								}
							}}
							sx={{
								width: "10rem",
								height: "2.5rem",
								borderRadius: "3rem",
								fontSize: "14px",
								fontFamily: "SFUIText-Semibold",
								paddingTop: "0",
								backgroundColor: "white",
								border: "1px solid #DFDFDF",
							}}>
							{/* <MenuItem value={"today"}>Today</MenuItem> */}
							<MenuItem value={"week"}>This Week</MenuItem>
							<MenuItem value={"month"}>This Month</MenuItem>
						</Select>
					</FormControl>
				</div>
			</div>
			<div className="flex flex-auto">
				{loading ? (
					<div className="h-full w-full flex items-center justify-center">
						<CircularProgress />
					</div>
				) : showNothingToShow ? (
					<div className="text-gray-400 flex h-full w-full items-center justify-center font-medium">Nothing to show</div>
				) : (
					<div className="grid md:grid-cols-3 items-center w-full grid-cols-1 gap-2">
						<div className="col-span-1 md:h-full h-[150px] w-full">
							<ResponsiveContainer width="100%" height="100%">
								<PieChart>
									<Tooltip filterNull={false} />
									<Pie
										data={pieChartData}
										cx="50%"
										cy="50%"
										labelLine={false}
										label={renderCustomizedLabel}
										outerRadius={"90%"}
										fill="#8884d8"
										dataKey="value"
										paddingAngle={1}
										minAngle={1}>
										{pieChartData.map((entry, index) => (
											<Cell key={`cell-${index}`} fill={entry.fill} />
										))}
									</Pie>
								</PieChart>
							</ResponsiveContainer>
						</div>
						<div className="flex justify-center gap-3 col-span-2 md:h-[150px] h-full py-2">
							<div>
								<div>
									<div className="text-sm text-gray-600 flex items-center gap-2">
										<span className="h-2 w-2 rounded-full" style={{ backgroundColor: "transparent" }}></span> Total Expenses
									</div>
									<div className="text-md ml-4 font-medium">
										{currencySymbol}
										{totalExpenseData?.value.toFixed(2) ?? 0} ({totalExpenseData?.quantity ?? 0})
									</div>
								</div>
								<div>
									<div className="text-sm text-gray-600 flex items-center gap-2">
										<span className="h-2 w-2 rounded-full" style={{ backgroundColor: "#40A636" }}></span>
										Approved Expenses
									</div>
									<div className="text-md ml-4 font-medium">
										{currencySymbol}
										{totalApprovedExpenses?.value.toFixed(2) ?? 0} ({totalApprovedExpenses?.quantity ?? 0})
									</div>
								</div>
								<div>
									<div className="text-sm text-gray-600 flex items-center gap-2">
										<span className="h-2 w-2 rounded-full" style={{ backgroundColor: "#25A8F4" }}></span>
										Paid Out
									</div>
									<div className="text-md ml-4 font-medium">
										{currencySymbol}
										{paidOut?.value.toFixed(2) ?? 0} ({paidOut?.quantity ?? 0})
									</div>
								</div>
							</div>
							<div>
								<div className="flex flex-col">
									<div className="text-sm text-gray-600 flex items-center gap-2">
										<div className="h-2 w-2 rounded-full" style={{ backgroundColor: "#EF9943" }}></div>
										Pending Expenses
									</div>
									<div className="text-md ml-4 font-medium">
										{currencySymbol}
										{totalPendingExpenses?.value.toFixed(2) ?? 0} ({totalPendingExpenses?.quantity ?? 0})
									</div>
								</div>
								<div >
									<div className="text-sm text-gray-600 flex items-center gap-2">
										<span className="h-2 w-2 rounded-full" style={{ backgroundColor: "#F54747" }}></span>
										Rejected Expenses
									</div>
									<div className="text-md ml-4 font-medium">
										{currencySymbol}
										{totalRejectedExpenses?.value.toFixed(2) ?? 0} ({totalRejectedExpenses?.quantity ?? 0})
									</div>
								</div>
							</div>
						</div>
					</div>
				)}
			</div>
		</div>
	);
}

export default Right;
