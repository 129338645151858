/* eslint-disable react/jsx-key */
/* eslint-disable react-hooks/exhaustive-deps */
import { gql, useQuery, useSubscription } from "@apollo/client";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";

import moment from "moment";
import { useEffect, useState } from "react";

type Props = {};
type Data = {
	currentDay: number;
	previousDay: number;
};
const FORMS_FILLED_QUERY = gql`
	query GetFormsFilledData($startDate: Date!, $endDate: Date!) {
		get_completedFormsv2_count_by_adminID_date(startDate: $startDate, endDate: $endDate)
	}
`;
const ORDERS_FILLED_QUERY = gql`
	query GetOrdersFilledData($startDate: Date!, $endDate: Date!) {
		get_orders_count_by_adminID_date(startDate: $startDate, endDate: $endDate)
	}
`;
const PHOTOS_FILLED_QUERY = gql`
	query GetPhotosFilledData($startDate: Date!, $endDate: Date!) {
		get_photos_count_by_adminID_date(startDate: $startDate, endDate: $endDate)
	}
`;
const CLIENTS_FILLED_QUERY = gql`
	query GetClientsFilledData($startDate: Date!, $endDate: Date!) {
		get_clients_count_by_adminID_date(startDate: $startDate, endDate: $endDate)
	}
`;

const WATCH_FORMS_CHANGED = gql`
	subscription {
		completed_formdata_add {
			completedFormID
		}
	}
`;

const WATCH_PHOTOS_CHANGED = gql`
	subscription {
		photo_add {
			photoID
		}
	}
`;
const WATCH_ORDERS_CHANGED = gql`
	subscription {
		order_add {
			client {
				clientName
			}
			orderValue
			orderItems {
				amount
			}
		}
	}
`;

const WATCH_CUSTOMERS_CHANGED = gql`
	subscription {
		client_add {
			clientID
			clientName
			employeeID
			companyID
		}
	}
`;

export default function Left({}: Props) {
	const [formsData, setFormsData] = useState<Data>({ previousDay: 0, currentDay: 0 });
	const [photosData, setPhotosData] = useState<Data>({ previousDay: 0, currentDay: 0 });
	const [ordersData, setOrdersData] = useState<Data>({ previousDay: 0, currentDay: 0 });
	const [customersData, setCustomersData] = useState<Data>({ previousDay: 0, currentDay: 0 });

	const { data: formDataPreviousDay, loading: loadingFormPreviousDay } = useQuery(FORMS_FILLED_QUERY, {
		variables: {
			startDate: moment().subtract(1, "day").format("YYYY-MM-DD"),
			endDate: moment().subtract(1, "day").format("YYYY-MM-DD"),
		},
	});
	const { data: formDataCurrentDay, loading: loadingFormCurrentDay } = useQuery(FORMS_FILLED_QUERY, {
		variables: {
			startDate: moment().format("YYYY-MM-DD"),
			endDate: moment().format("YYYY-MM-DD"),
		},
	});
	const { data: ordersDataPreviousDay, loading: loadingOrdersPreviousDay } = useQuery(ORDERS_FILLED_QUERY, {
		variables: {
			startDate: moment().subtract(1, "day").format("YYYY-MM-DD"),
			endDate: moment().subtract(1, "day").format("YYYY-MM-DD"),
		},
	});
	const { data: ordersDataCurrentDay, loading: loadingOrdersCurrentDay } = useQuery(ORDERS_FILLED_QUERY, {
		variables: {
			startDate: moment().format("YYYY-MM-DD"),
			endDate: moment().format("YYYY-MM-DD"),
		},
	});
	const { data: photosDataPreviousDay, loading: loadingPhotosPreviousDay } = useQuery(PHOTOS_FILLED_QUERY, {
		variables: {
			startDate: moment().subtract(1, "day").format("YYYY-MM-DD"),
			endDate: moment().subtract(1, "day").format("YYYY-MM-DD"),
		},
	});
	const { data: photosDataCurrentDay, loading: loadingPhotosCurrentDay } = useQuery(PHOTOS_FILLED_QUERY, {
		variables: {
			startDate: moment().format("YYYY-MM-DD"),
			endDate: moment().format("YYYY-MM-DD"),
		},
	});
	const { data: clientsDataPreviousDay, loading: loadingClientsPreviousDay } = useQuery(CLIENTS_FILLED_QUERY, {
		variables: {
			startDate: moment().subtract(1, "day").format("YYYY-MM-DD"),
			endDate: moment().format("YYYY-MM-DD"),
		},
	});
	const { data: clientsDataCurrentDay, loading: loadingClientsCurrentDay } = useQuery(CLIENTS_FILLED_QUERY, {
		variables: {
			startDate: moment().format("YYYY-MM-DD"),
			endDate: moment().add(1, "day").format("YYYY-MM-DD"),
		},
	});

	useSubscription(WATCH_FORMS_CHANGED, {
		onData: () => {
			setFormsData((prev) => ({
				...prev,
				currentDay: prev.currentDay + 1,
			}));
		},
	});

	useSubscription(WATCH_PHOTOS_CHANGED, {
		onData: () => {
			setPhotosData((prev) => ({
				...prev,
				currentDay: prev.currentDay + 1,
			}));
		},
	});

	useSubscription(WATCH_ORDERS_CHANGED, {
		onData: () => {
			setOrdersData((prev) => ({
				...prev,
				currentDay: prev.currentDay + 1,
			}));
		},
	});

	useSubscription(WATCH_CUSTOMERS_CHANGED, {
		onData: () => {
			setCustomersData((prev) => ({
				...prev,
				currentDay: prev.currentDay + 1,
			}));
		},
	});

	useEffect(() => {
		if (formDataPreviousDay != undefined) {
			setFormsData((prev) => ({
				...prev,
				previousDay: formDataPreviousDay?.get_completedFormsv2_count_by_adminID_date,
			}));
		}
	}, [loadingFormPreviousDay]);

	useEffect(() => {
		if (formDataCurrentDay != undefined) {
			setFormsData((prev) => ({
				...prev,
				currentDay: formDataCurrentDay?.get_completedFormsv2_count_by_adminID_date,
			}));
		}
	}, [loadingFormCurrentDay]);

	useEffect(() => {
		if (ordersDataPreviousDay != undefined) {
			setOrdersData((prev) => ({
				...prev,
				previousDay: ordersDataPreviousDay?.get_orders_count_by_adminID_date,
			}));
		}
	}, [loadingOrdersPreviousDay]);

	useEffect(() => {
		if (ordersDataCurrentDay != undefined) {
			setOrdersData((prev) => ({
				...prev,
				currentDay: ordersDataCurrentDay?.get_orders_count_by_adminID_date,
			}));
		}
	}, [loadingOrdersCurrentDay]);

	useEffect(() => {
		if (photosDataPreviousDay != undefined) {
			setPhotosData((prev) => ({
				...prev,
				previousDay: photosDataPreviousDay?.get_photos_count_by_adminID_date,
			}));
		}
	}, [loadingPhotosPreviousDay]);

	useEffect(() => {
		if (photosDataCurrentDay != undefined) {
			setPhotosData((prev) => ({
				...prev,
				currentDay: photosDataCurrentDay?.get_photos_count_by_adminID_date,
			}));
		}
	}, [loadingPhotosCurrentDay]);

	useEffect(() => {
		if (clientsDataPreviousDay != undefined) {
			setCustomersData((prev) => ({
				...prev,
				previousDay: clientsDataPreviousDay?.get_clients_count_by_adminID_date,
			}));
		}
	}, [loadingClientsPreviousDay]);

	useEffect(() => {
		if (clientsDataCurrentDay != undefined) {
			setCustomersData((prev) => ({
				...prev,
				currentDay: clientsDataCurrentDay?.get_clients_count_by_adminID_date,
			}));
		}
	}, [loadingClientsCurrentDay]);

	return (
		<div className="grid grid-cols-2 gap-3 Bottom1">
			<div className="bg-white rounded-md border p-3 flex gap-2 flex-col justify-evenly items-start">
				<div className="text-lg">Forms Filled</div>
				<div className="text-2xl font-heavy">
					{formsData?.currentDay}
					{formsData?.currentDay >= formsData?.previousDay ? (
						<span>
							<ArrowUpwardIcon
								sx={{
									height: "14px",
									color: "green",
									width: "14px",
								}}
							/>
							<span style={{ fontSize: "14px", color: "green" }}>
								{formsData?.previousDay != 0 ? (
									(((formsData?.currentDay - formsData?.previousDay) / formsData?.previousDay) * 100).toFixed()
								) : (
									<span>0%</span>
								)}
							</span>
						</span>
					) : (
						<span>
							<ArrowDownwardIcon
								sx={{
									height: "14px",
									color: "red",
									width: "14px",
								}}
							/>
							{formsData?.previousDay != 0 ? (
								<span style={{ fontSize: "14px", color: "red" }}>
									{(((formsData?.previousDay - formsData?.currentDay) / formsData?.previousDay) * 100).toFixed()}%
								</span>
							) : (
								<span style={{ fontSize: "14px", color: "red" }}>0%</span>
							)}
						</span>
					)}
				</div>
				<div className="border rounded-full px-2 inline-block">
					<p className="font-medium">{formsData?.previousDay} Yesterday</p>
				</div>
			</div>

			<div className="bg-white rounded-md border p-3 flex gap-2 flex-col justify-evenly items-start">
				<div className="text-lg">Photos Uploaded</div>
				<div className="text-2xl font-heavy">
					{photosData?.currentDay}
					{photosData?.currentDay >= photosData?.previousDay ? (
						<span>
							<ArrowUpwardIcon
								sx={{
									height: "14px",
									color: "green",
									width: "14px",
								}}
							/>

							{photosData?.previousDay != 0 ? (
								<span style={{ fontSize: "14px", color: "green" }}>
									{(((photosData?.currentDay - photosData?.previousDay) / photosData?.previousDay) * 100).toFixed()}%
								</span>
							) : (
								<span style={{ color: "green" }}>0%</span>
							)}
						</span>
					) : (
						<span>
							<ArrowDownwardIcon
								sx={{
									height: "14px",
									color: "red",
									width: "14px",
								}}
							/>
							<span style={{ fontSize: "14px", color: "red" }}>
								{(((photosData?.previousDay - photosData?.currentDay) / photosData?.previousDay) * 100).toFixed()}%
							</span>
						</span>
					)}
				</div>
				<div className="border rounded-full px-2 inline-block">
					<p className="font-medium">{photosData?.previousDay} Yesterday</p>
				</div>
			</div>

			<div className="bg-white rounded-md border p-3 flex gap-2 flex-col justify-evenly items-start">
				<div className="text-lg">New Clients</div>
				<div className="text-2xl font-heavy">
					{customersData?.currentDay}
					{customersData?.currentDay >= customersData?.previousDay ? (
						<span>
							<ArrowUpwardIcon
								sx={{
									height: "14px",
									color: "green",
									width: "14px",
								}}
							/>

							{customersData?.previousDay != 0 ? (
								<span style={{ fontSize: "14px", color: "green" }}>
									{(
										((customersData?.currentDay - customersData?.previousDay) / customersData?.previousDay) *
										100
									).toFixed()}
									%
								</span>
							) : (
								<span style={{ fontSize: "14px", color: "green" }}>0%</span>
							)}
						</span>
					) : (
						<span>
							<ArrowDownwardIcon
								sx={{
									height: "14px",
									color: "red",
									width: "14px",
								}}
							/>
							{customersData?.previousDay != 0 ? (
								<span style={{ fontSize: "14px", color: "red" }}>
									{(
										((customersData?.previousDay - customersData?.currentDay) / customersData?.previousDay) *
										100
									).toFixed()}
									%
								</span>
							) : (
								<span style={{ color: "red" }}>0%</span>
							)}
						</span>
					)}
				</div>
				<div className="border rounded-full px-2 inline-block">
					<p className="font-medium">{customersData?.previousDay} Yesterday</p>
				</div>
			</div>

			<div className="bg-white rounded-md border p-3 flex gap-2 flex-col justify-evenly items-start">
				<div className="text-lg">Orders Submitted</div>
				<div className="text-2xl font-heavy">
					{ordersData?.currentDay}
					{ordersData?.currentDay >= ordersData?.previousDay ? (
						<span>
							<ArrowUpwardIcon
								sx={{
									height: "14px",
									color: "green",
									width: "14px",
								}}
							/>

							{ordersData?.previousDay != 0 ? (
								<span style={{ fontSize: "14px", color: "green" }}>
									{(((ordersData?.currentDay - ordersData?.previousDay) / ordersData?.previousDay) * 100).toFixed()}%
								</span>
							) : (
								<span style={{ fontSize: "14px", color: "green" }}>0%</span>
							)}
						</span>
					) : (
						<span>
							<ArrowDownwardIcon
								sx={{
									height: "14px",
									color: "red",
									width: "14px",
								}}
							/>
							<span style={{ fontSize: "14px", color: "red" }}>
								{(((ordersData?.previousDay - ordersData?.currentDay) / ordersData?.previousDay) * 100).toFixed()}%
							</span>
						</span>
					)}
				</div>
				<div className="border rounded-full px-2 inline-block">
					<p className="font-medium">{ordersData?.previousDay} Yesterday</p>
				</div>
			</div>
		</div>
	);
}
