/* eslint-disable react-hooks/exhaustive-deps */
import { gql, useQuery, useSubscription } from "@apollo/client";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";
import { useContext, useEffect, useMemo, useState } from "react";
import { ALL_DASHBOARD_USERS } from "../../../../../schema";
import DataGridPart from "./DataGridPart";
import { FormControl, MenuItem, Select } from "@mui/material";
import { GraphDataContext } from "../context";
import axios from "../../../../../utils/axios";
import _debounce from "lodash/debounce";
import { WORKING_STATUS_V2 } from "../../../../../@types";
import { ROOT_STATE } from "../../../../../redux";
import { useSelector } from "react-redux";
import { PRODUCT_ID, UNOLO_BIOMETRIC } from "../../../../../constants";

type Props = {
	targetData:
	| {
		employeeID: number;
		eventTypeID: number;
		lat: number;
		lon: number;
		timestamp: string;
		address: string;
		internalEmpID: string;
	}
	| undefined;
};
type ROW_TYPE = {
	name: string;
	id: string;
	img: string;
	siteName: string | null;
	location?: string;
	status?: string;
	internalEmpID: string;
	lastAttendanceLocation: string | null;
	lastAttendanceTime: string | null;
	lastAttendance: string | null;
	lastLocationTimestamp: string | null;
	lastAttendanceTimestamp: string | null;
	employeeID: number;
};

const WATCH_LIVE_LOCATION_EVENT = gql`
	subscription WatchLocationEvent {
		location {
			employeeID
			timestamp
			age
			insertTime
			date
			lat
			lon
		}
	}
`;

export default function Right({ targetData }: Props) {
	const productID = window ? localStorage.getItem(PRODUCT_ID) ?? "2" : "2";
	const user = useSelector((state: ROOT_STATE) => state.user);
	const [rows, setRows] = useState<ROW_TYPE[]>([]);
	const [ogRows, setOgRows] = useState<ROW_TYPE[]>([]);
	const [loading, setLoading] = useState(true);
	const [searchTerm1, setSearchTerm1] = useState("");
	const [dropdownValue, setDropdownValue] = useState("all");
	const LIMIT = typeof window !== "undefined" && window.innerWidth < 600 ? 10 : window.innerWidth < 960 ? 15 : 20;
	const [variables, setVariables] = useState<any>({
		skip: 0,
		take: LIMIT,
		searchKey: "",
		workingStatusFilter: WORKING_STATUS_V2.ALL_EMPLOYEES,
	});
	const { data: gridData, loading: loading1 } = useQuery(ALL_DASHBOARD_USERS, {
		variables: {
			...variables,
			searchKey: variables.searchKey.trim(),
		}
	});
	const { graphData, setGraphdata } = useContext(GraphDataContext);

	const handleSearchChange = useMemo(
		() =>
			_debounce(function (e) {
				setVariables((variables: any) => ({
					...variables,
					searchKey: e.target.value,
				}));
			}, 500),
		[]
	);
	if (loading) {
	}
	const handleRealtimeLocationChange = async (data: any) => {
		const resp = await getLocation([{ lat: data.data?.location.lat, lng: data.data?.location.lon, id: 0 }]);
		const tempRows: ROW_TYPE[] = [];
		ogRows.forEach((item) => {
			if (item.employeeID === data.data?.location.employeeID) {
				tempRows.push({
					...item,
					location: resp.data[0]?.locality,
					lastLocationTimestamp: data.data?.location.timestamp,
				});
			} else {
				tempRows.push(item);
			}
		});
		if (searchTerm1.length > 0) {
			const tempList: ROW_TYPE[] = tempRows.filter((obj) =>
				JSON.stringify(obj.name).replace(/ /g, "").toLowerCase().includes(searchTerm1.replace(/ /g, "").toLowerCase())
			);
			setRows([...tempList]);
		} else {
			setRows([...tempRows]);
		}
		setOgRows([...tempRows]);
	};
	useSubscription(WATCH_LIVE_LOCATION_EVENT, {
		onData: ({ data }) => handleRealtimeLocationChange(data),
	});
	const getStatus = (attendance: { eventTypeID: number; timestamp: string }, location?: any) => {
		if (attendance?.eventTypeID == 9) {
			return "Punched Out";
		} else if (attendance?.eventTypeID == 8) {
			if (location && location.timestamp && moment(attendance.timestamp).isBefore(moment(location.timestamp))) {
				if (moment().diff(moment(location.timestamp), "minutes") > 60 && productID != UNOLO_BIOMETRIC) {
					return "Inactive";
				} else {
					return "Punched In";
				}
			} else {
				if (moment().diff(moment(attendance.timestamp), "minutes") > 60 && productID != UNOLO_BIOMETRIC) {
					return "Inactive";
				} else {
					return "Punched In";
				}
			}
			//  parseInt(duration.asMinutes()) % 60
		} else {
			return "Never Marked Attendance";
		}
	};
	const getLocation = async (geoArray: { lat: number; lng: number; id: number }[], tempArray?: ROW_TYPE[]) => {
		const url = `${process.env.NEXT_PUBLIC_BACKEND_URL}/api/v1/bulkReverseGeocode`;
		const data = await axios.post(url, JSON.stringify({ reverseGeocodePayload: geoArray }), {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
		});
		if (tempArray != undefined) {
			data?.data?.data?.forEach((item: { locality: string; id: number }) => {
				let tempObj = tempArray[item.id];
				tempObj = {
					...tempObj,
					location: item.locality,
				};
				tempArray[item.id] = tempObj;
			});
			return tempArray;
		} else {
			return data?.data;
		}
	};
	const getAndFormatDataForGridRow = async () => {
		let tempArray: ROW_TYPE[] = [];
		const geoArray: { lat: number; lng: number; id: number }[] = [];
		gridData?.get_users_by_admin_id_v2?.data.forEach((item: any, index: number) => {
			tempArray.push({
				employeeID: item?.employeeID,
				internalEmpID: item?.internalEmpID,
				id: uuidv4(),
				img: item?.imgUrl,
				siteName: item?.lastAttendance != null ? item.lastAttendance[0]?.siteInfo?.clientName || "NA" : "NA",
				name: `${item?.firstName} ${item?.lastName}`,
				location: item?.lastLocation === null ? "NA" : "Loading...",
				lastLocationTimestamp: item?.lastLocation == null ? null : item?.lastLocation[0]?.timestamp,
				lastAttendanceTimestamp: item?.lastAttendance == null ? null : item?.lastAttendance[0]?.timestamp,
				lastAttendance:
					item?.lastAttendance != null
						? item.lastAttendance[0]?.eventTypeID == 8
							? "Punched In"
							: item.lastAttendance[0]?.eventTypeID == 9
								? "Punched Out"
								: "NA"
						: "Never Marked Attendance",
				status:
					item?.workingStatus != null
						? item?.workingStatus == "APPROVED_LEAVE"
							? "On Leave"
							: item?.workingStatus == "WEEKLY_OFF"
								? "Weekly Off"
								: item?.workingStatus == "PUBLIC_HOLIDAY"
									? "On Leave"
									: item?.workingStatus == "NORMAL_DAY"
										? item?.lastAttendance != null
											? item?.lastLocation != null
												? getStatus(item?.lastAttendance[0], item?.lastLocation[0])
												: getStatus(item?.lastAttendance[0])
											: "Punched Out"
										: "NA"
						: "Never Marked Attendance",
				lastAttendanceLocation: item?.lastAttendance !== null ? item.lastAttendance[0]?.siteName : null,
				lastAttendanceTime:
					item?.lastAttendance !== null ? moment(item?.lastAttendance[0]?.timestamp).format("hh:mm a") : null,
			});
			if (item?.lastLocation != null) {
				geoArray.push({
					lat: item.lastLocation[0]?.lat,
					lng: item.lastLocation[0]?.lon,
					id: index,
				});
			}
		});
		setRows([...tempArray]);
		tempArray = await getLocation(geoArray, tempArray);
		setRows([...tempArray]);
		setOgRows([...tempArray]);
	};
	const handleDropdownChange = (value: string) => {
		// let isFound = false;
		// const tempArray: ROW_TYPE[] = [];
		// if (value == "all") {
		// 	setRows([...ogRows]);
		// 	if (nothingToShow) {
		// 		setNothingToShow(false);
		// 	}
		// 	return;
		// }
		// ogRows.forEach((row) => {
		// 	if (row.status == value) {
		// 		isFound = true;
		// 		tempArray.push(row);
		// 	}
		// });
		// if (!isFound) {
		// 	setNothingToShow(true);
		// } else {
		// 	setRows([...tempArray]);
		// 	if (nothingToShow) {
		// 		setNothingToShow(false);
		// 	}
		// }
		if (value == "all") {
			setVariables((prev: any) => ({
				...prev,
				workingStatusFilter: "",
			}));
			return;
		}
		setVariables((prev: any) => ({
			...prev,
			workingStatusFilter: value,
		}));
	};
	const handleRealtimeAttendanceChange = async () => {
		{
			if (targetData != undefined) {
				const tempArray: ROW_TYPE[] = [];
				const tempGraphData: { name: string; value: number; color: string }[] = [];
				ogRows.forEach((item) => {
					if (item.employeeID == targetData?.employeeID) {
						let tempObject: ROW_TYPE = {
							name: "",
							id: "",
							img: "",
							siteName: "",
							internalEmpID: "",
							lastAttendanceLocation: null,
							lastAttendanceTime: null,
							lastAttendance: null,
							lastLocationTimestamp: null,
							lastAttendanceTimestamp: null,
							employeeID: 0,
						};
						if (targetData.eventTypeID == 8) {
							if (item.status == "Punched Out") {
								graphData.forEach((item1) => {
									if (item1.name == "Punched In") {
										const tempObject = {
											...item1,
											value: item1.value + 1,
										};
										tempGraphData.push(tempObject);
									} else if (item.lastAttendance == item1.name) {
										const tempObject = {
											...item1,
											value: item1.value - 1,
										};
										tempGraphData.push(tempObject);
									}
									// if no last attendance, then subtract from Punched Out
									else if (item1.name == "Punched Out") {
										const tempObject = {
											...item1,
											value: item1.value - 1,
										};
										tempGraphData.push(tempObject);
									} else {
										tempGraphData.push(item1);
									}
								});
							}
							tempObject = {
								...item,
								lastAttendance: "Punched In",
								status: "Punched In",
								lastAttendanceTimestamp: targetData.timestamp,
								lastAttendanceLocation: targetData.address,
							};
						} else if (targetData?.eventTypeID == 9) {
							if (item.status == "Punched In") {
								graphData.forEach((item1) => {
									if (item1.name == "Punched Out") {
										const tempObject = {
											...item1,
											value: item1.value + 1,
										};
										tempGraphData.push(tempObject);
									} else if (item.lastAttendance == item1.name) {
										const tempObject = {
											...item1,
											value: item1.value - 1,
										};
										tempGraphData.push(tempObject);
									} else {
										tempGraphData.push(item1);
									}
								});
							}
							tempObject = {
								...item,
								lastAttendance: "Punched Out",
								status: "Punched Out",
								lastAttendanceTimestamp: targetData.timestamp,
								lastAttendanceLocation: targetData.address,
							};
						}
						tempArray.push(tempObject);
					} else tempArray.push(item);
				});
				if (tempGraphData.length != 0 && setGraphdata != undefined) {
					setGraphdata([...tempGraphData]);
				}
				if (searchTerm1.length > 0) {
					const tempList: ROW_TYPE[] = tempArray.filter((obj) =>
						JSON.stringify(obj.name)
							.replace(/ /g, "")
							.toLowerCase()
							.includes(searchTerm1.replace(/ /g, "").toLowerCase())
					);
					setRows([...tempList]);
				} else {
					setRows([...tempArray]);
				}
				setOgRows([...tempArray]);
			}
		}
	};
	useEffect(() => {
		if (!loading1) {
			getAndFormatDataForGridRow();
		} else {
			setLoading(true);
		}
	}, [loading1]);

	useEffect(() => {
		handleRealtimeAttendanceChange();
	}, [targetData]);

	const showTotalUsersOrFilteredUsers = () =>
		gridData?.get_users_by_admin_id_v2?.unpaginatedCount ? gridData?.get_users_by_admin_id_v2?.unpaginatedCount : 0;

	return (
		<div className="col-span-2 row-span-2 bg-white rounded-md px-3 py-2 h-[calc(600px_+_1rem)] flex flex-col gap-2 border">
			<div className="flex items-center justify-between  flex-wrap">
				<div>
					Employees ({showTotalUsersOrFilteredUsers()}){`    `}
					<b style={{ color: "red", fontWeight: 400 }}>
						{!user.tz || user.tz == "" || user.tz == null || user.tz == undefined
							? "(Problem loading timezone.)"
							: null}
					</b>
				</div>

				<div className="flex-auto gap-2 items-center justify-end flex">
					<div>
						<FormControl fullWidth>
							<Select
								labelId="demo-simple-select-label"
								id="demo-simple-select"
								value={dropdownValue}
								label={""}
								onChange={(e) => {
									setDropdownValue(e.target.value);
									handleDropdownChange(e.target.value);
								}}
								sx={{
									height: "2.5rem",
									borderRadius: "3rem",
									fontSize: "14px",
									fontFamily: "SFUIText-Semibold",

									paddingTop: "0",
									backgroundColor: "white",
									border: "1px solid #DFDFDF",

									width: "124px",
								}}>
								<MenuItem value={"all"}>All</MenuItem>
								<MenuItem value={"Punched In"}>Punched In</MenuItem>
								<MenuItem value={"Punched Out"}>Punched Out</MenuItem>
								{productID == UNOLO_BIOMETRIC ? null : <MenuItem value={"Inactive"}>Inactive</MenuItem>}
							</Select>
						</FormControl>
					</div>
					<div style={{ maxWidth: "180px", minWidth: "100px", display: "flex" }}>
						<input
							className="search"
							value={searchTerm1}
							style={{ maxWidth: "180px", minWidth: "100px", display: "flex" }}
							placeholder="Search Here"
							onChange={(e) => {
								handleSearchChange(e);
								setSearchTerm1(e.target.value);
							}}
						/>
					</div>
				</div>
			</div>
			<DataGridPart rows={rows} setVariables={setVariables} loading1={loading1} />
		</div>
	);
}
