import type { NextPage } from "next";
import { DashboardLayout } from "../components";
import MainOverview from "../components/Dashboard/MainOverview";

const Attendance: NextPage = () => (
	<DashboardLayout title="Overview | Dashboard" tab={0}>
		<MainOverview />
	</DashboardLayout>
);

export default Attendance;
