/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-key */

import { useQuery } from "@apollo/client";
import { CircularProgress } from "@mui/material";
import { useEffect, useState } from "react";
import { GET_SITE_WISE_STATUS } from "../../../../../../schema/dashboard";

const Bottom = () => {
	const [siteDataToShow, setSiteDataToShow] = useState<any>(null);
	const { data: siteData, loading: loadingSiteData } = useQuery(GET_SITE_WISE_STATUS, {
		variables: {
			siteFilter: true,
		},
	});

	useEffect(() => {
		if (!loadingSiteData && siteData?.get_attendance_summary && Array.isArray(siteData?.get_attendance_summary)) {
			setSiteDataToShow(siteData?.get_attendance_summary);
		}
	}, [loadingSiteData, siteData?.get_attendance_summary]);

	return (
		<div className="bg-white rounded-md border px-3 py-2 flex flex-col min-h-[300px]">
			<div className="flex items-center justify-between pb-1 gap-2 border-b">Sitewise Attendance</div>
			{loadingSiteData ? (
				<div className="flex-auto flex items-center justify-center">
					<CircularProgress />
				</div>
			) : (
				<div className="flex-auto w-full h-full flex items-center justify-center">
					{siteDataToShow?.length === 0 && <p className="text-gray-400 font-medium text-sm">No Data found</p>}
					{siteDataToShow?.map((item: any) => (
						<div className="team">
							<div className="name">{item.siteInfo ? item.siteInfo.clientName : "Name Not Found"} </div>
							<div className="datas">
								<div className="data">
									<div className="color" style={{ backgroundColor: "#40A636" }}></div>
									<div>{item.attendanceSummary?.onlineUsers}</div>
								</div>
							</div>
						</div>
					))}
				</div>
			)}
		</div>
	);
};

export default Bottom;
