import { Cell, Legend, Pie, PieChart, ResponsiveContainer, Tooltip } from "recharts";
// import CountUp from "react-countup";
// import VisibilitySensor from "react-visibility-sensor";
import { useQuery } from "@apollo/client";
import { useContext, useEffect, useState } from "react";
import { CircularProgress } from "@mui/material";
import { GET_FE_STATUS } from "../../../../../../../schema";
import { GraphDataContext } from "../../../context";
import { PRODUCT_ID, UNOLO_BIOMETRIC } from "../../../../../../../constants";
type Props = {};
type TASK_DATA = {
	punchedIn: number;
	punchedOut: number;
	inActive: number;
	totalUsers: number;
};

function GraphTop({}: Props) {
	const productID = window ? localStorage.getItem(PRODUCT_ID) ?? "2" : "2";
	const { graphData, setGraphdata } = useContext(GraphDataContext);
	const { data: data1, loading: loading1 } = useQuery(GET_FE_STATUS, {
		variables: {
			teamFilter: false,
		},
	});
	const [taskData, setTaskData] = useState<TASK_DATA>({
		punchedIn: 0,
		punchedOut: 0,
		inActive: 0,
		totalUsers: 0,
	});

	const renderColorfulLegendText = (value: string) => (
		<span
			style={{
				fontSize: "12px",
				color: "#000000",
				fontFamily: "SFUIText-Regular, Helvetica, Arial, sans-serif",
				fontWeight: "400",
			}}>
			{value}
		</span>
	);
	const renderLegend = (props: any) => {
		const { payload } = props;
		return (
			<ul className="flex gap-2 items-start justify-center mt-2">
				{payload.map(
					(
						entry: {
							color: any;
							value: any;
							payload: {
								value: any;
							};
						},
						index: any
					) => (
						<li className="flex flex-col items-center flex-1" key={`item-${index}`}>
							<div className="flex items-center gap-2">
								<span className="h-2 w-2 rounded-full" style={{ backgroundColor: entry.color }}>
								</span>
								<span className="text-sm font-semibold">{entry.payload.value}</span>
							</div>
							<p className="text-xs text-center font-medium">{entry.value}</p>
						</li>
					)
				)}
			</ul>
		);
	};
	const formatDataForGraph = () => {
		const tempArray = [
			{
				name: "Punched In",
				value: data1?.get_attendance_summary[0]?.attendanceSummary?.onlineUsers || 0,
				color: "#40A636",
			},
			{
				name: "Punched Out",
				value: data1?.get_attendance_summary[0]?.attendanceSummary?.offlineUsers || 0,
				color: "#F54747",
			},
		];
		if (productID !== UNOLO_BIOMETRIC) {
			tempArray.push({
				name: "Inactive",
				value: data1?.get_attendance_summary[0]?.attendanceSummary?.inactiveUsers || 0,
				color: "#EF9943",
			});
		}
		if (setGraphdata != undefined) {
			setGraphdata([...tempArray]);
		}
	};
	useEffect(() => {
		if (!loading1) {
			const tempTaskData: TASK_DATA = {
				punchedIn: data1?.get_attendance_summary?.[0]?.attendanceSummary?.onlineUsers || 0,
				punchedOut: data1?.get_attendance_summary?.[0]?.attendanceSummary?.offlineUsers || 0,
				inActive: data1?.get_attendance_summary?.[0]?.attendanceSummary?.inactiveUsers || 0,
				totalUsers: data1?.get_attendance_summary?.[0]?.attendanceSummary?.totalUsers || 0,
			};
			setTaskData({
				...tempTaskData,
			});
			formatDataForGraph();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [loading1]);

	return loading1 ? (
		<div className="h-[calc(100%_-_28px)] p-2 flex items-center justify-center">
			<CircularProgress />
		</div>
	) : (
			<div className="h-[calc(100%_-_28px)] p-2 relative">
				<ResponsiveContainer width="100%" height="100%">
					<PieChart>
						<Legend
							align="center"
							layout="horizontal"
							verticalAlign="top"
							height={18}
							wrapperStyle={{ fontSize: "8px" }}
							iconType="circle"
							formatter={renderColorfulLegendText}
							content={renderLegend}
						/>
						<Tooltip />

						<Pie
							data={graphData}
							cy={"80%"}
							startAngle={180}
							endAngle={0}
							innerRadius={"80%"}
							outerRadius={"100%"}
							fill="#8884d8"
							paddingAngle={0}
							dataKey="value">
							{graphData?.map((entry, index) => (
								<Cell key={`cell-${index}`} fill={entry?.color} />
							))}
						</Pie>
					</PieChart>
				</ResponsiveContainer>
				<div className="text-center absolute bottom-12 left-1/2 -translate-x-1/2">
					<div className="">{taskData?.totalUsers}</div>
					<div className="">All Employees</div>
				</div>
			</div>
	);
}

export default GraphTop;
